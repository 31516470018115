<template>
  <div>
    <div class="header">
      <div class="title">
        {{ teacherName }}
      </div>
    </div>
    <div class="table-container">
      <table class="scheduleTable">
        <thead>
          <tr class="lesson-header">
            <td class="day-column"></td>
            <td
              v-for="(lessonName, index) in scheduleLessonsNames"
              :key="index"
              class="lesson-td"
            >
              <div class="lesson-name">{{ lessonName.name }}</div>
            </td>
          </tr>
          <!-- <tr class="time-header">
            <td></td>
            <td
              v-for="(time, index) in schedulesFromTo"
              :key="index"
              class="time-item"
            >
              <span class="from">{{
                time.from ? getFullTime(time.from) : "From"
              }}</span>
              <span class="to">{{
                time.to ? getFullTime(time.to) : "To"
              }}</span>
            </td>
          </tr> -->
        </thead>
        <tbody v-for="(schedule, dayIndex) in schedules" :key="dayIndex">
          <tr class="full-day">
            <td class="day">{{ schedule.day }}</td>
            <td
              class="lesson-card"
              v-for="(lessonCard, index) in schedule.lessons"
              :key="index"
            >
              <div
                v-for="(lessonItem, i) in lessonCard"
                :key="i"
                class="lesson-container"
                :class="lessonItem.type == 'break' ? 'full-height' : ''"
              >
                <div v-if="lessonItem.type == 'lesson'" class="single-lesson">
                  <div class="subject-item">
                    {{
                      lessonItem.from ? getFullTime(lessonItem.from) + " :" : ""
                    }}
                    {{ lessonItem.to ? getFullTime(lessonItem.to) : "" }}
                  </div>
                  <div class="class-item">
                    {{ lessonItem.class }}
                  </div>
                  <div class="subject-item">
                    {{ lessonItem.subject }}
                  </div>
                </div>
                <div class="break" v-if="lessonItem.type == 'break'">
                  <span>{{ $t("BREAK") }}</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { teacherScheduleMixin } from "../../mixins/schedules/teacherScheduleMixin";
export default {
  name: "TeacherScheduleComponent",
  mixins: [teacherScheduleMixin],
  props: ["editable", "classId", "academicId"],
  data() {
    return {
      schedules: [],
      scheduleLessonsNames: [],
      schedulesFromTo: [],
      teacherName: ""
    };
  },
  mounted() {
    this.getSchedules();
  },
  methods: {
    getFullTime(time) {
      var type = "";
      var H = time.split(":")[0];
      var M = time.split(":")[1];
      if (H == "00") {
        H = 12;
        type = "AM";
      } else if (H == "12") {
        type = "PM";
      } else if (parseInt(H) > 12) {
        type = "PM";
        H = H % 12;
      } else if (parseInt(H) < 12) {
        type = "AM";
      }
      return H + ":" + M + " " + type;
    }
  }
};
</script>

<style scoped>
@import "../../assets/css/ScheduleTable.css";
</style>
