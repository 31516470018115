<template>
  <div>
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <TeacherScheduleComponent></TeacherScheduleComponent>
  </div>
</template>

<script>
import TeacherScheduleComponent from "../../components/Schedule/TeacherScheduleComponent";
export default {
  name: "TeacherSchedule",
  components: { TeacherScheduleComponent },
};
</script>

<style></style>
