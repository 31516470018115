import axios from "axios";
export const teacherScheduleMixin = {
  data() {
    return {
      teacherId: this.$router.currentRoute.params.teacherId
    };
  },
  methods: {
    getSchedules() {
      axios
        .get(this.getApiUrl + "/teachers/schedule/" + this.teacherId, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(res => {
          this.schedules = res.data.data.schedules;
          this.scheduleLessonsNames = res.data.data.scheduleLessonsNames;
          this.schedulesFromTo = res.data.data.schedulesFromTo;
          this.teacherName = res.data.data.teacher;
        });
    }
  },
  mounted() {
    this.getSchedules();
  }
};
